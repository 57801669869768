import React from "react"
import { Link } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import { Fv } from "components/fv"

const CleaningPage = () => {
    return (
        <Layout>
            <div className="cleaning design-style">
                <Fv
                    pcFv="https://balukoweb.blob.core.windows.net/images/repair/pc_repair_mv.jpg"
                    spFv="https://balukoweb.blob.core.windows.net/images/repair/sp_repair_mv.jpg"
                    fvTitle="Clothing Repair"
                    color="white"
                />
                <FadeIn.Up>
                    <section className="lead-area">
                        {/* PC */}
                        <h2 className="pc title tx-center">
                            大切な衣類をもっと心地よく、もっと長く楽しむ。
                            <br />
                            バルコのお直し
                        </h2>
                        {/* SP */}
                        <h2 className="sp title tx-center">
                            大切な衣類をもっと心地よく、
                            <br />
                            もっと長く楽しむ。
                            <br />
                            バルコのお直し
                        </h2>
                        {/* PC */}
                        <p className="pc lead tx-center fw-b">
                            傷んでしまった服、サイズが合わなくなってしまった服、<br />
                            もう着れないと諦める前に「直す」という選択肢があります。<br />
                            もう一度心地よく着ていただけるように、熟練のスタッフの手で丁寧に修理。<br />
                            一着一着を大切に、長く着続けられるお手伝いをすることで、<br />
                            人と服の未来を少しずつ明るくしていきます。
                        </p>
                        {/* SP */}
                        <p className="sp lead fw-b">
                            傷んでしまった服、サイズが合わなくなってしまった服、 もう着れないと諦める前に「直す」という選択肢があります。<br />
                            もう一度心地よく着ていただけるように、熟練のスタッフの手で丁寧に修理。<br />
                            一着一着を大切に、長く着続けられるお手伝いをすることで、人と服の未来を少しずつ明るくしていきます。
                        </p>
                        <div className="title-area-style tx-center">
                            <h2 className="title e">Price</h2>
                            <p className="sub-title fw-b">価格</p>
                        </div>
                    </section>
                </FadeIn.Up>

                <FadeIn.Up>
                    <section className="main-contents">
                        <div className="price-list tx-center">
                            <div className="price-list-inner">
                                <img
                                    className="lazy pc"
                                    src="https://balukoweb.blob.core.windows.net/images/repair/02_WEB_Komae_Repair_menu.png"
                                    width={740}
                                    alt=""
                                    loading="lazy"
                                />
                                <img
                                    className="lazy sp"
                                    src="https://balukoweb.blob.core.windows.net/images/repair/02_WEB_Komae_Repair_menu.png"
                                    width="100%"
                                    alt=""
                                    loading="lazy"
                                />
                            </div>
                        </div>

                        <div className="title-area-style tx-center">
                            <h2 className="title e">Store</h2>
                            <p className="sub-title fw-b">取扱い店舗</p>
                        </div>
                        <div className="store-contents">
                            <div className="store-contents-inner">
                                <ul className="store-contents-list">
                                    {/* 狛江店 */}
                                    <li className="store-contents-item">
                                        <div className="store-contents-item-box">
                                            <img
                                                className="lazy pc"
                                                src="https://balukoappprod.blob.core.windows.net/images/shops/2024-01/1705548087_komae_1.jpg"
                                                width={413}
                                                alt=""
                                                loading="lazy"
                                            />
                                            <img
                                                className="lazy sp"
                                                src="https://balukoappprod.blob.core.windows.net/images/shops/2024-01/1705548087_komae_1.jpg"
                                                width="100%"
                                                alt=""
                                                loading="lazy"
                                            />
                                            <div className="store-contents-item-box-detail">
                                                <p className="store-name fw-b">
                                                    狛江
                                                </p>
                                                <p className="store-address">
                                                    東京都狛江市和泉本町2-33-22
                                                </p>
                                                <Link className="store-link" to="/baluko-komae/">
                                                    店舗詳細
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </FadeIn.Up>
            </div>
        </Layout>
    )
}
export const Head = () => (
    <Seo
        title="Clothing Repair - バルコのお直し"
        description="大切な一着を長く楽しむために、熟練のスタッフの手で丁寧に修理します。サイズ調整、丈詰め、穴あき・スレ・キズ直し、ミシン刺し、ボタン付けなど、お気軽にご相談ください。"
    />
)
export default CleaningPage
